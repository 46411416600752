import React from "react";

const SidebarAd = () => {
  return (
    <div className="adunit adunit--home-sidebar">
      <div className="adunit__container">
      <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
      </div>
    </div>
  );
};

export default SidebarAd;
